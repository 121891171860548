<template>
  <app-layout>
    <div class="px-5 container container-center">
      <async-object
        should-fetch
        :fetch-method="() => $store.dispatch('orders/fetchById', {
          id: $route.params.id
        })"
      >
        <div class="heading d-flex justify-content-between mb-5">
          <h2>Commande {{ reference }}</h2>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="mb-4">
              <router-link class="btn btn-link btn-sm" :to="{ name: 'orders.index' }" >
                <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
              </router-link>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="header-title mb-4">Sommaire</h4>
                <dl class="row">
                  <dt class="col-4">Date de commande</dt>
                  <dd class="col-8">{{ $shortDateTime(orderDate) }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Sous-total</dt>
                  <dd class="col-8">{{ $money(subTotal) }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Taxes</dt>
                  <dd class="col-8">{{ $money(totalTaxes) }}</dd>
                </dl>
                <dl class="row">
                  <dt class="col-4">Statut</dt>
                  <dd class="col-8">{{ orderStatus }}</dd>
                </dl>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="card mb-4">
              <div class="card-body">
                <h4 class="header-title mb-4">Adresse de livraison</h4>
                <p class="address">
                  {{ shippingAddress.name }}
                  <template v-if="shippingAddress.company">
                    <br> {{ shippingAddress.company }}
                  </template>
                  <br>{{ shippingAddress.address }}
                  <template v-if="shippingAddress.address2">
                    <br> {{ shippingAddress.address2 }}
                  </template>
                  <br>{{ shippingAddress.city }}, {{ shippingAddress.province }}, {{ shippingAddress.postalCode }}
                  <br>Canada
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="card mb-4">
              <div class="card-body p-0">
                <h4 class="header-title with-padding">Articles</h4>

                <div class="table-responsive">
                  <table class="table nowrap-table">
                    <thead>
                    <th>Article</th>
                    <th class="col-100">Quantité</th>
                    <th class="col-100">Prix</th>
                    <th class="col-100">Sous-total</th>
                    <th class="col-100">Taxes</th>
                    <th class="col-100">Total</th>
                    <th>Statut</th>
                    </thead>
                    <tbody>
                    <template v-for="item in orderItems">
                      <order-item-row :item="item" :key="item.id"></order-item-row>
                    </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </async-object>
    </div>
  </app-layout>
</template>

<script>
import { get } from 'lodash-es'
import orderStatus from '@/mixins/orderStatus'
import formatsCurrency from '@/mixins/formatsCurrency'
import formatsDate from '@/mixins/formatsDate'
import OrderItemRow from '@/components/orders/OrderItemRow'

export default {
  mixins: [orderStatus, formatsCurrency, formatsDate],
  components: {
    OrderItemRow
  },
  computed: {
    order () {
      return this.$store.getters['orders/getDetailsById'](this.$route.params.id)
    },
    orderItems () {
      return this.order ? this.order.orderItems : []
    },
    reference () {
      return get(this.order, 'reference')
    },
    orderDate () {
      return get(this.order, 'createdAt')
    },
    subTotal () {
      return get(this.order, 'subtotal')
    },
    totalTaxes () {
      const taxes = get(this.order, 'taxes', {})
      let total = 0

      for (const tax in taxes) {
        total += Number(taxes[tax])
      }

      return total
    },
    orderStatus () {
      const status = get(this.order, 'status')
      return this.getOrderStatusText(status)
    },
    shippingAddress () {
      return get(this.order, 'shippingAddress', {})
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  line-height: 24px;
}
</style>
